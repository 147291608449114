import LiveSessionTile from '@components/live-session/LiveSessionTile/LiveSessionTile'
import { LiveSession, SearchResult } from '@lib/types'
import Link from 'next/link'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SwiperCore, { A11y, Autoplay, Keyboard, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/a11y'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Swiper, SwiperSlide } from 'swiper/react'
SwiperCore.use([Navigation, Autoplay, Keyboard, A11y])

const LiveSessionSlider = ({
  session,
}: {
  session: SearchResult<LiveSession>
}) => {
  const swiperRef = React.useRef(null)
  const [showButton, setShowButton] = useState({
    sessionNext: true,
    sessionPrev: false,
  })

  const setShowButtons = (isBeginning: boolean, isEnd: boolean) => {
    if (isBeginning) setShowButton({ ...showButton, sessionPrev: false })
    else if (isEnd) setShowButton({ ...showButton, sessionNext: false })
    else setShowButton({ ...showButton, sessionPrev: true, sessionNext: true })
  }

  const { t } = useTranslation()

  return (
    <div>
      <div className="flex justify-between pt-4 w-full">
        <h2 className="font-semibold ">{t('SESSIONS.live_sessions')}</h2>

        <div className="flex items-center gap-3">
          <Link href={'/sessions'} className="text-primary underline">
            {t('See All')}
          </Link>
          <div className="flex gap-2">
            <div
              onClick={() => {
                swiperRef.current.slidePrev()
              }}
              className={`btn btn-circle  btn-sm text-2xl ${
                showButton.sessionPrev ? '' : 'btn-disabled'
              }`}
            >
              &lt;
            </div>

            <div
              onClick={() => swiperRef?.current.slideNext()}
              className={`btn btn-circle  btn-sm text-2xl ${
                showButton.sessionNext ? '' : 'btn-disabled'
              }`}
            >
              &gt;
            </div>
          </div>
        </div>
      </div>
      <div className="flex">
        <Swiper
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper
            setShowButtons(swiper.isBeginning, swiper.isEnd)
          }}
          onActiveIndexChange={() => {
            setShowButtons(
              swiperRef.current.isBeginning,
              swiperRef.current.isEnd
            )
          }}
          id="session"
          className="box-border"
          allowSlideNext={true}
          allowSlidePrev
          keyboard={{
            enabled: true,
          }}
          spaceBetween={30}
          breakpoints={{
            500: {
              slidesPerView:
                session.items?.length > 2 ? 2 : session.items?.length,
              spaceBetween: 30,
            },
            768: {
              slidesPerView:
                session.items?.length > 2 ? 3 : session.items?.length,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView:
                session.items?.length > 3 ? 3.5 : session.items?.length,
              spaceBetween: 30,
            },
            1284: {
              slidesPerView:
                session.items?.length > 4 ? 4 : session.items?.length,
              spaceBetween: 30,
            },

            1536: {
              slidesPerView:
                session.items?.length > 4 ? 4 : session.items?.length,
              spaceBetween: 30,
            },
          }}
        >
          {session.items?.map((session) => (
            <SwiperSlide key={session?.id}>
              <LiveSessionTile
                session={session}
                positioning="vertical"
                refetch={() => {}}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {session.totalCount < 1 && <div>No Live Sessions Found!</div>}
    </div>
  )
}

export default LiveSessionSlider
