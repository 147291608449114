import { CourseTile } from '@components/common'
import analytics from '@lib/analytics/analytics'
import { Course } from '@lib/types'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SwiperCore, { A11y, Autoplay, Keyboard, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/a11y'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Swiper, SwiperSlide } from 'swiper/react'
SwiperCore.use([Navigation, Autoplay, Keyboard, A11y])

const CourseSlider = ({ course }: { course: Course[] }) => {
  const swiperRef = React.useRef(null)
  const [showButton, setShowButton] = useState({
    next: true,
    prev: false,
  })
  const router = useRouter()

  const setShowButtons = (isBeginning: boolean, isEnd: boolean) => {
    if (isBeginning) setShowButton({ ...showButton, prev: false })
    else if (isEnd) setShowButton({ ...showButton, next: false })
    else setShowButton({ ...showButton, prev: true, next: true })
  }

  const { t } = useTranslation()

  const handleClick = (
    e: React.MouseEvent<Element, MouseEvent>,
    course: Course
  ) => {
    e.preventDefault()
    analytics.track('select_content', {
      content_type: 'course',
      item_id: course.slug,
    })
    router.push(`/courses/${course.slug}`)
  }

  return (
    <div>
      <div className="flex justify-between pt-4 mt-9">
        <h2 className="font-semibold prose-lg  pt-4">{t('courses')}</h2>
        <div className="flex items-center gap-3">
          <Link href={'/search?type=Course'} className="text-primary underline">
            {t('See All')}
          </Link>
          <div className="flex gap-2">
            <div
              onClick={() => swiperRef?.current?.slidePrev()}
              className={`btn btn-circle  btn-sm text-2xl ${
                showButton.prev ? '' : 'btn-disabled'
              }`}
            >
              &lt;
            </div>
            <div
              onClick={() => swiperRef?.current?.slideNext()}
              className={`btn btn-circle  btn-sm text-2xl ${
                showButton.next ? '' : 'btn-disabled'
              }`}
            >
              &gt;
            </div>
          </div>
        </div>
      </div>
      <div className="flex">
        <Swiper
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper
            setShowButtons(swiper.isBeginning, swiper.isEnd)
          }}
          onActiveIndexChange={() => {
            setShowButtons(
              swiperRef.current.isBeginning,
              swiperRef.current.isEnd
            )
          }}
          id="course"
          className="box-border"
          allowSlideNext={true}
          allowSlidePrev
          keyboard={{
            enabled: true,
          }}
          spaceBetween={30}
          breakpoints={{
            500: {
              slidesPerView: course?.length > 2 ? 2 : course?.length,
              spaceBetween: 30,
            },
            768: {
              slidesPerView: course?.length > 2 ? 3 : course?.length,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: course?.length > 3 ? 3.5 : course?.length,
              spaceBetween: 30,
            },
            1284: {
              slidesPerView: course?.length > 4 ? 4 : course?.length,
              spaceBetween: 30,
            },

            1536: {
              slidesPerView: course?.length > 4 ? 4 : course?.length,
              spaceBetween: 30,
            },
          }}
        >
          {course?.map((item) => (
            <SwiperSlide key={item?.id}>
              <CourseTile
                key={item?.id}
                course={item}
                onClick={(e) => handleClick(e, item)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {course.length < 1 && <div>No Live Sessions Found!</div>}
    </div>
  )
}

export default CourseSlider
