import CourseSlider from '@components/ui/CourseSlider'
import ExamSlider from '@components/ui/ExamSlider'
import LiveSessionSlider from '@components/ui/LiveSessionSlider'
import { Config } from '@config/constants'
import * as Sentry from '@sentry/nextjs'
import {
  AuthAction,
  useAuthUser,
  withAuthUser,
  withAuthUserTokenSSR,
} from 'next-firebase-auth'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

const Index = ({ session, course, exam }) => {
  const user = useAuthUser()
  const router = useRouter()
  useEffect(() => {
    if (user.id) {
      router.replace('/dashboard')
    }
  }, [user, user.id, router])
  useEffect(() => {
    const body = document.querySelector('body')
    body.classList.add('lp')

    return () => {
      body.classList.remove('lp')
    }
  })

  return (
    <div className="max-w-screen-xl mx-auto">
      <LiveSessionSlider session={session} />
      <CourseSlider course={course} />
      <ExamSlider exam={exam} />
    </div>
  )
}

export const getServerSideProps = withAuthUserTokenSSR({
  appPageURL: '/dashboard',
  whenAuthed: AuthAction.REDIRECT_TO_APP,
})(async ({ AuthUser, locale, query }) => {
  if (AuthUser.id) {
    return {
      redirect: {
        destination: '/dashboard',
        permanent: false,
      },
      props: {
        eventType: '',
        scope: '',
      },
    }
  }

  try {
    const session = await fetch(
      `${Config.API_BASE_URL}/api/sessions?page=1&size=10&sortType=Trending`
    ).then(async (data) => await data.json())

    const course = await fetch(
      `${Config.API_BASE_URL}/api/Courses/Trending`
    ).then(async (data) => await data.json())

    const exam = await fetch(
      `${Config.API_BASE_URL}/api/questionset?page=1&sortBy=CreatedOn&sortType=Descending`
    ).then(async (data) => await data.json())

    return {
      props: {
        session,
        course,
        exam,
      },
    }
  } catch (err) {
    Sentry.captureException(err)
    return {
      props: {},
    }
  }
})

// @ts-ignore
export default withAuthUser()(Index)
