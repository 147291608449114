import McqCard from '@components/Mcq/McqCard'
import { QuestionSetPostReturn, SearchResult } from '@lib/types'
import Link from 'next/link'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SwiperCore, { A11y, Autoplay, Keyboard, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/a11y'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Swiper, SwiperSlide } from 'swiper/react'
SwiperCore.use([Navigation, Autoplay, Keyboard, A11y])

const ExamSlider = ({
  exam,
}: {
  exam: SearchResult<QuestionSetPostReturn>
}) => {
  const swiperRef = React.useRef(null)
  const [showButton, setShowButton] = useState({
    next: true,
    prev: false,
  })

  const setShowButtons = (isBeginning: boolean, isEnd: boolean) => {
    if (isBeginning) setShowButton({ ...showButton, prev: false })
    else if (isEnd) setShowButton({ ...showButton, next: false })
    else setShowButton({ ...showButton, prev: true, next: true })
  }

  const { t } = useTranslation()

  return (
    <div>
      <div className="flex justify-between pt-4 mt-8 mb-4 w-full">
        <h2 className="font-semibold ">{t('Exams')}</h2>

        <div className="flex items-center gap-3">
          <Link href={'/exams'} className="text-primary underline">
            {t('See All')}
          </Link>
          <div className="flex gap-2">
            <div
              onClick={() => swiperRef?.current?.slidePrev()}
              className={`btn btn-circle  btn-sm text-2xl ${
                showButton.prev ? '' : 'btn-disabled'
              }`}
            >
              &lt;
            </div>
            <div
              onClick={() => swiperRef?.current?.slideNext()}
              className={`btn btn-circle  btn-sm text-2xl ${
                showButton.next ? '' : 'btn-disabled'
              }`}
            >
              &gt;
            </div>
          </div>
        </div>
      </div>
      <div className="flex">
        <Swiper
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper
            setShowButtons(swiper.isBeginning, swiper.isEnd)
          }}
          onActiveIndexChange={() => {
            setShowButtons(
              swiperRef.current.isBeginning,
              swiperRef.current.isEnd
            )
          }}
          id="exam"
          className="box-border"
          allowSlideNext={true}
          allowSlidePrev
          keyboard={{
            enabled: true,
          }}
          spaceBetween={30}
          breakpoints={{
            500: {
              slidesPerView: exam.items?.length > 2 ? 2 : exam.items?.length,
              spaceBetween: 30,
            },
            768: {
              slidesPerView: exam.items?.length > 2 ? 3 : exam.items?.length,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: exam.items?.length > 3 ? 3.5 : exam.items?.length,
              spaceBetween: 30,
            },
            1284: {
              slidesPerView: exam.items?.length > 4 ? 4 : exam.items?.length,
              spaceBetween: 30,
            },

            1536: {
              slidesPerView: exam.items?.length > 4 ? 4 : exam.items?.length,
              spaceBetween: 30,
            },
          }}
        >
          {exam.items?.map((exam) => (
            <SwiperSlide key={exam?.id}>
              <McqCard
                key={exam?.id}
                data={exam}
                positioning="vertical"
                refetch={() => {}}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {exam.totalCount < 1 && <div>No Live Sessions Found!</div>}
    </div>
  )
}

export default ExamSlider
