import { Avatar } from '@components/common'
import { Earth } from '@components/icons'
import LockIcon from '@components/icons/LockIcon'
import { LiveSessionEnrollmentStatus, SCOPE } from '@config/constants'
import { deleteLiveSessions } from '@lib/services/LiveSession'
import { LiveSession } from '@lib/types'
import getCourseOgImageUrl from '@lib/utils/getCourseOgImageUrl'
import getRemainingTime from '@lib/utils/getRemainingTime'
import toaster from '@lib/utils/notify'
import { convertTimeWithTitles } from '@lib/utils/time-utils'
import cn from 'classnames'
import moment from 'moment'
import { useAuthUser } from 'next-firebase-auth'
import { useTheme } from 'next-themes'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import JoinButton from './JoinButton'
import s from './LiveSessionTile.module.css'

type Props = {
  session: LiveSession
  className?: string
  positioning?: string
  refetch: Function
}

const LiveSessionTile: FC<Props> = ({
  session,
  className,
  positioning = 'horizontal',
  refetch,
}) => {
  const { id, slug, courseSlug, hasRecording } = session

  const { t } = useTranslation()
  const currentUser = useAuthUser()
  const router = useRouter()

  const locale = router.locale
  /**Delete Event list  */

  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [confirm, setConfirm] = useState<boolean>()

  const confirmDeleteLiveSession = async () => {
    if (id) {
      try {
        setLoading(true)
        setMessage('')
        await deleteLiveSessions(locale, currentUser, id)
        setLoading(false)
        refetch(id)
        toaster.success(t('live_session_success_delete'))
      } catch (r) {
        setLoading(false)
        const err = await r.json()
        setMessage(err.message || err.Message)
        toaster.failure(t('live_session_unable_delete'))
      }
    }
  }
  const { resolvedTheme: theme, setTheme } = useTheme()

  return (
    <div className={`${cn(s.root)} h-full mt-4 `}>
      <div
        className={
          ` rounded-md  card  card-bordered shadow-lg bg-base-200 relative` +
          ` ${s[positioning]}`
        }
        style={{ height: '90%' }}
      >
        <Link
          href={`/sessions/${slug}`}
          passHref
          className="absolute z-10 w-full h-full"
        >
          <div></div>
        </Link>

        <Image
          alt={session?.name}
          height={225}
          width={400}
          src={getCourseOgImageUrl(
            session.user,
            session.name,
            session.imageUrl,
            theme
          )}
        />

        <div className={` card-body ${s.sessionCard} `}>
          <div className=" card-title">
            <div className="flex flex-col-reverse justify-start  w-full">
              <div className="flex space-x-1 w-9/12">
                <h2 className={`text-ellipsis line-clamp-1 text-lg`}>
                  {session?.name}
                </h2>
              </div>
              <div className="flex space-x-2">
                <span
                  className={` min-w-[4rem] w-auto  badge  badge-outline  ${
                    session?.price > 0 ? ' badge-primary' : ' badge-accent'
                  }`}
                >
                  <span className="break-all">
                    {session?.price > 0
                      ? `${t('npr')} ${session?.price.toFixed(0)}`
                      : t('Free')}
                  </span>
                </span>
                <span className="">
                  {session?.scope == SCOPE.PUBLIC ? (
                    <span className=" tooltip" data-tip={t('public')}>
                      <Earth className="h-5 w-5 "></Earth>
                    </span>
                  ) : (
                    <span
                      className=" tooltip"
                      data-tip={t('Only my team member')}
                    >
                      <LockIcon className="h-5 w-5"></LockIcon>
                    </span>
                  )}
                </span>
                {session?.userStatus === LiveSessionEnrollmentStatus.HOST ? (
                  <span className=" badge bg-[#FE6196]  text-black">
                    {t('host')}
                  </span>
                ) : session?.userStatus ===
                  LiveSessionEnrollmentStatus.ENROLLED ? (
                  <span className=" badge bg-[#03FCB5] text-white">
                    {t('event_enroll')}
                  </span>
                ) : session?.userStatus ===
                  LiveSessionEnrollmentStatus.MODERATOR ? (
                  <span className=" badge bg-[#000dff] text-white">
                    {t('moderator')}
                  </span>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div className={`${s.profileLink}`}>
            <div className="flex justify-start">
              <Link
                href={`/u/${session.user.userId}`}
                passHref
                className="z-20"
              >
                <div className="flex items-center text-2xs pt-2 ">
                  <Avatar user={session?.user} size="small"></Avatar>
                  &nbsp;
                  {''}
                  <span className="text-ellipsis line-clamp-1 ml-1">
                    {session?.user.firstName} {''}
                    {session?.user.lastName}
                  </span>
                </div>
              </Link>
              <span className="flex items-center text-primary text-2xs pt-2 mx-4 min-w-[110px]">
                <p>
                  {getRemainingTime(
                    moment.utc(session?.nearestStartTime).toLocaleString()
                  )}
                </p>
              </span>
            </div>
          </div>
          {session?.duration != 0 && (
            <span className="flex items-center text-2xs pt-2 pl-1 text-primary">
              {t('Duration')}: {convertTimeWithTitles(session?.duration * 60)}
            </span>
          )}
          <div className=" card-actions justify-end">
            {(session?.userStatus === LiveSessionEnrollmentStatus.ENROLLED ||
              session?.userStatus === LiveSessionEnrollmentStatus.HOST ||
              session?.userStatus ===
                LiveSessionEnrollmentStatus.MODERATOR) && (
              <div className="flex items-center justify-end space-x-2">
                <JoinButton
                  currentUser={currentUser}
                  formRequired={session.formRequired}
                  formSubmitted={session.formSubmitted}
                  locale={locale}
                  roomSlug={session.roomSlug}
                  teamId={session.teamId}
                />
                {hasRecording &&
                  session?.userStatus !==
                    LiveSessionEnrollmentStatus.MODERATOR && (
                    <Link
                      passHref
                      href={`/recording/${slug}`}
                      className="w-auto h-8 text-xs  btn  btn-outline border-[#37d2b8] text-[#37d2b8]  btn-sm rounded-none capitalize  hover:text-white hover:bg-[#113645] z-20"
                    >
                      {t('Recordings')}
                    </Link>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LiveSessionTile
