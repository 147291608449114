import { Button, Modal, useUI } from '@components/ui'
import UserForm from '@components/User/UserForm'
import { getUserForm } from '@lib/services'
import { getJWT } from '@lib/services/LiveSession'
import { AuthUserContext } from 'next-firebase-auth'
import router from 'next/router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

const JoinButton = ({
  locale,
  currentUser,
  roomSlug,
  formRequired,
  formSubmitted,
  teamId,
}: {
  locale: string
  currentUser: AuthUserContext
  roomSlug: string
  formRequired: boolean
  formSubmitted: boolean
  teamId: string
}) => {
  const { t } = useTranslation()
  const { openModal, setModalView, modalView, closeModal, displayModal } =
    useUI()
  const [error, setError] = useState()
  const USERFORM_MODEL = `USERFORM_MODEL=> ${roomSlug}`
  const ERROR_MODEL_LIVE_SESSION = `ERROR_MODEL_LIVE_SESSION=> ${roomSlug} `

  const [active, setActive] = useState(false)

  const { data: userFormData, isLoading: userFormLoading } = useQuery(
    ['userForm', currentUser.id],
    () => getUserForm(currentUser, locale, teamId),
    {
      enabled: active,
    }
  )

  const join = async () => {
    try {
      const r = await getJWT(locale, currentUser, roomSlug)
      router.push(`/meet/${roomSlug}`)
    } catch (err) {
      setError(err.message)
      setModalView(ERROR_MODEL_LIVE_SESSION)
      openModal()
    }
  }
  const joinDemo = () => {
    if (formRequired && !formSubmitted) {
      setActive(true)
      setModalView(USERFORM_MODEL)
      openModal()
    } else {
      join()
    }
  }
  return (
    <>
      {modalView === USERFORM_MODEL && (
        <Modal open={displayModal} onClose={closeModal}>
          <UserForm
            teamId={teamId}
            onDone={join}
            data={userFormData}
            locale={locale}
            currentUser={currentUser}
          />
        </Modal>
      )}
      {modalView === ERROR_MODEL_LIVE_SESSION && error && (
        <Modal open={displayModal} onClose={closeModal}>
          <div className="p-2 mt-5">
            <div className="text-lg flex justify-center font-semibold">
              {error}
            </div>

            <div className="flex justify-center mt-5">
              <Button
                variant="slim"
                onClick={closeModal}
                className="bg-primary"
              >
                {t('Close')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <Button variant="slim" onClick={joinDemo} className="mr-2 z-20">
        {t('Join_btn')}
      </Button>
    </>
  )
}

export default JoinButton
