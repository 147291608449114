import { formatDistanceToNow, isValid } from 'date-fns'

const getRemainingTime = (date: string) => {
  const parsedDate = new Date(date)
  if (isValid(parsedDate))
    return formatDistanceToNow(parsedDate, {
      addSuffix: true,
    })

  return ''
}

export default getRemainingTime
